import React from 'react';
import { Spring } from 'react-spring';

const h1 = {
    fontSize: "22px",
    fontWeight: "600"
}

const h2 = {
    fontSize: "18px",
    fontWeight: "400"
}

const p = {
    marginTop: "20px",
    marginBottom: "50px",
    fontWeight: "300",
    fontStyle: "italic"
}

export default function Header() {
    return(
        <Spring
            from = {{ opacity: 0, marginTop: -500 }}
            delay = { 500 }
            to = {{ opacity: 1, marginTop: 0 }}
        >
            { props => (
                <div style={props}>
                    <section id="about">
                        <div class="margins">
                            <div class="left-column">
                                <h1 style={h1}>Mikael Scherdin (PhD)</h1>
                                <h2 style={h2}>Forskare och grundare.</h2>
                                <p style={p}>Vi är ett forskningsbaserat företag som arbetar med utveckling av innovationsstödsystem. Vårt fokus är att utveckla system och att arbeta med träning och utbildning av experter som arbetar med innovationsutveckling i större privata företag och offentlig sektor.</p>

                                <div style={{ fontSize: "22px", fontWeight: "600", marginBottom: "10px" }}>Senaste projekt</div>
                                <div class="proj">2019 <span>Forskare Gävle Högskola</span></div>
                                <div class="proj">2019 <span>Region Gävleborg</span></div>
                                <div class="proj">2017 <span>Processledare Lettland &amp; Litauen</span></div>
                                <div class="proj">2015 <span>Processledare Narva, Estland</span></div>
                            </div>
                        </div>
                        <div class="wave wave1"></div>
                        <div class="wave wave2"></div>
                        <div class="wave wave3"></div>
                        <div class="wave wave4"></div>
                    </section>
                </div>
            )}
        </Spring>
    )
}