import React from 'react';
import { Spring } from 'react-spring';

export default function Splintermind() {
    return (
        <Spring
            from = {{ opacity: 0, marginRight: -3000 }}
            to = {{ opacity: 1, marginRight: 0 }}
        >
            { props => (
                <div style={props}>
                    <div class="margins-splint" id="splintermind">
                        <div class="row">
                            <div class="column">
                                <h1>Splintermind</h1>
                                <p style={{ marginTop: 40}}>Splintermind är ett helägt aktiebolag av Mikael Scherdin, som startades 1998. Röda tråden är kreativitet i alla dess former; 
                                <br></br>från konst till dagens område att arbeta med stödsystem för kreativitet och innovation.</p>
                            </div>
                            
                            <div class="column">
                                <h1>System</h1>
                                <p style={{ marginTop: 40 }}><b>Invite To Do® bygger innovationsförmåga.</b><br></br>
                                Konkurrenskraften sitter i våra hjärnor.
                                <br></br><br></br>
                                Vi vet att människors hjärnor är nutidens viktigaste resurs. De har kapacitet att tänka nytt och smart.
                                Den som satsar på en effektiv strategi för interna idéprocesser får en garanti för framtiden.
                                Vårt system tar till vara idékraften och ingjuter mod i dina tänkande medarbetare.
                                <br></br><br></br>
                                <a href="https://invitetodo.com/" target="_blank" rel="noopener noreferrer">> InviteToDo</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Spring>
    )
}