import React from 'react';
import { Spring } from 'react-spring';

const biografi = {
    backgroundColor: "white",
    minHeight: "60vh"
}

const bioHeading = {
    color: "black",
    textAlign: "left",
    left: "12.5%",
    position: "relative",
    fontSize: "32px",
    top: "calc(0)"
}

export default function Biografi() {
    return (
        <Spring
            from = {{ opacity: 0, marginRight: -2000}}
            to = {{ opacity: 1, marginRight: 0 }}
        >
            { props => (
                <div style={props}>
                    <div style={biografi} id="systems">
                        <h1 class="systems">Biografi</h1>
                        <div class="box">
                            <p class="bio">Mikael Scherdin innehar en doktorstitel i entreprenörskap från Uppsala universitet. Han har arbetat med forskning inom områdena kreativitet, entreprenörskap, intraprenörskap, innovation, samt ekonomiska ekosystem. Scherdin har en lång karriär inom entreprenörskap både i praktik och inom akademisk forskning. De senaste åren har han arbetat med horisontell innovationsledning och utvecklat metoder, verktyg och processer inom horisontellt ledarskap. Han arbetar just nu med publikationer på området.</p>
                            
                            <div class="second">
                                <h2>PDF Filer</h2>
                                <div class="pdf">
                                    <a href="https://www.dropbox.com/s/g76mizwt9sj6n7i/CV_MikaelScherdin_Pdf.pdf?dl=1" target="_blank" rel="noopener noreferrer">PU</a>
                                    <a href="https://www.dropbox.com/s/64l1cqgkfctqftv/CV_Experiences.pdf?dl=1" target="_blank" rel="noopener noreferrer">CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Spring>
    )
}