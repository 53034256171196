import React from 'react';
import { Spring } from 'react-spring';

export default function Footer() {
    return (
        <Spring
            from = {{ opacity: 0 }}
            to = {{ opacity: 1 }}
        >
            { props => (
                <div style={props}>
                    <div class="skew-c" id="contact"></div>
                    <footer>
                        <div class="margins-two">
                            <h2>Kontakt</h2>
                            <p>Mikael Scherdin<br></br>
                            +46 70 686 75 69<br></br>
                            </p>
                            <p id="email">
                            email
                            </p>
                        </div>
                        <div class="copyright">Splintermind.com © 2020 All rights reserved.</div>
                    </footer>
                </div>
            )}
        </Spring>
    )
}

window.onload = function() {
    this.document.getElementById('email').innerHTML = "mikael@splintermind.com";
}