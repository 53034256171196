import React from 'react';
import { Spring } from 'react-spring';

const logo = require('./img/logo.jpg');

export default function Menu() {
    return (
        <Spring
            from = {{ opacity: 0, marginTop: -500 }}
            to = {{ opacity: 1, marginTop: 0 }}    
        >
            { props => (
                <div style={props}>
                    <div class="menu">
                        <div class="margins-menu">
                            <img src={logo} alt="splintermind logo"></img>
                            <div class="menu-items">
                                <a href="#about">Om Oss</a>
                                <a href="#splintermind">Splintermind</a>
                                <a href="#systems">System</a>
                                <a href="#contact">Kontakt</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}    
        </Spring> 
    )
}